import sortBy from "lodash/sortBy";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getUserIntegrationsStatistics } from "api/user";
import { IntegrationCard } from "components/common/Cards/IntegrationCard";
import { StaticChip } from "components/ui/chips/StaticChip";
import { LoadingDots } from "components/ui/LoadingDots";
import { Section } from "components/ui/Section";
import { Typography } from "components/ui/Typography";
import { useFetchedState } from "hooks/useFetchedState";
import { routes } from "routes/routes";
import { useStyles } from "./styles";

type TProps = {
	collapsible?: boolean;
};

const useIntegrationsStatistics = () => {
	const { data, fetchingState, isLoading, loadData } = useFetchedState(getUserIntegrationsStatistics);
	useEffect(() => {
		void loadData();
	}, [loadData]);
	return { data, fetchingState, isLoading };
};

export const MyPermissionsSection: FC<TProps> = ({ className, collapsible = true, innerRef }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const { data, fetchingState, isLoading } = useIntegrationsStatistics();
	const navigate = useNavigate();

	const integrations = useMemo(() => {
		if (!data) return null;
		return sortBy(data.valueSeq().toArray(), integration => integration.name.toLowerCase());
	}, [data]);

	const title = useMemo(() => {
		const myPermissionsTextTitle = t("pages.requests.myPermissionsSection.title");
		return (
			<>
				<Typography variant="body_sb" data-testid="my-permissions-title">
					{myPermissionsTextTitle}
				</Typography>
				<StaticChip size="small" variant="regular">
					{t("number", { value: Number(integrations?.length || 0) })}
				</StaticChip>
			</>
		);
	}, [integrations?.length, t]);

	const tiles = useMemo(() => {
		if (!integrations || integrations.length === 0) return null;
		return integrations.map(integration => (
			<IntegrationCard
				integration={integration}
				key={integration.id}
				resourcesCount={integration.resourcesCount}
				size="large"
				onClick={() =>
					void navigate({
						pathname: routes.requests.subRoutes!.myPermissions.location,
						search: `integrationId=${integration.id}`
					})
				}
				data-testid={`integration-card-${integration.id}`}
			/>
		));
	}, [integrations, navigate]);

	if (isLoading) return <LoadingDots center />;

	return (
		<Section
			collapsible={collapsible}
			className={className}
			defaultExpanded
			innerRef={innerRef}
			title={title}
			data-testid="my-permissions-section">
			{fetchingState === "Loaded" && !tiles ? (
				<div className={classes.emptyState} data-testid="empty-state">
					<Typography className={classes.emptyStateLabel}>
						{t("pages.requests.myPermissionsSection.emptyState")}
					</Typography>
				</div>
			) : (
				<div className={classes.tiles} data-testid="integration-tiles">
					{tiles}
				</div>
			)}
		</Section>
	);
};
