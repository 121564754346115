import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	body: {
		height: "100%",
		width: "100%"
	},
	emptyListMessage: {
		display: "flex",
		justifyContent: "center",
		color: "var(--color-grey-700, #5F6066)",
		paddingTop: "120px"
	}
});
