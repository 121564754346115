import { List } from "immutable";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RiskIndicatorIcon } from "components/ui/Icons/RiskIndicatorIcon";
import { getRiskLabel, mapSeverityToSeverityLevel } from "context/insightsContext";
import { DetectionsTooltip } from "./components/DetectionsTooltip";
import { useStyles } from "./styles";
import { InsightsIndicator, type TInsightsIndicator } from "../InsightsIndicator";
import type { TRisk } from "context/insightsContext";
import type { InsightsDetectionModel } from "models/InsightsDetectionModel";

type TProps = Omit<TInsightsIndicator, "Icon" | "level"> & {
	maxSeverity?: number;
	detections?: List<InsightsDetectionModel>;
};

export const getRiskColorFromLabel = (label: TRisk | null) => {
	if (!label) return "grey";
	return label === "risky" ? "red" : label === "caution" ? "orange" : "grey";
};

const getRiskColor = (severity?: number) => {
	if (!severity) return "grey";

	const label = getRiskLabel(severity);
	return getRiskColorFromLabel(label);
};

export const RiskIndicator: FC<TProps> = props => {
	const { t } = useTranslation("translation", { keyPrefix: "shared.insights" });
	const classes = useStyles();
	const { detections, maxSeverity } = props;
	const level = mapSeverityToSeverityLevel(maxSeverity);
	const color = props.color || getRiskColor(maxSeverity);

	const detectionsSorted = useMemo(() => detections?.sort((a, b) => b.severity - a.severity), [detections]);

	const tooltipContent = useMemo(() => {
		if (!detectionsSorted) return null;

		return (
			<DetectionsTooltip
				detections={detectionsSorted}
				titleLevel={level}
				titleColor={color}
				getLevel={mapSeverityToSeverityLevel}
				titleText={maxSeverity ? t(getRiskLabel(maxSeverity)!) : ""}
				isRisk
			/>
		);
	}, [detectionsSorted, level, color, t, maxSeverity]);

	return (
		<InsightsIndicator
			Icon={RiskIndicatorIcon}
			color={color}
			level={level}
			tooltipContent={tooltipContent}
			levelClassName={classes.level}
			{...props}
		/>
	);
};
