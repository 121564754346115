import { List, Record } from "immutable";
import {
	SeverityLevels,
	mapSeverityLevelToSeverity,
	PrivilegeLevels,
	mapPrivilegeLevelToPrivilege
} from "context/insightsContext";
import { InsightsDetectionModel } from "./InsightsDetectionModel";

interface IInsightsResponseNode {
	id: string;
	impactedEntities?: { id: string; primaryEmail: string }[];
}

interface ILatestDetectionDefinitionResponseNodes {
	nodes: ILatestDetectionDefinitionResponseNode[];
}

interface IDetectionDefinitionResponseNode {
	id: string;
	summary: string;
	metadata: {
		remediation: string;
		detail: string;
		concern: string;
	};
	insights: IInsightsResponseNode[];
}
interface ILatestDetectionDefinitionResponseNode {
	openDetectionCount: number;
	detectionDefinition: IDetectionDefinitionResponseNode;
}

interface IPrivilegeIdentity {
	id: string;
	email: string;
	sensitivity: number;
}

interface IPrivilegeResponseNode {
	identity: {
		id: string;
		primaryEmail: string | null;
		sensitivity?: number;
	};
}

interface IPrivilegeResponseNodes {
	nodes: IPrivilegeResponseNode[];
}

export interface IInsightsDetectionsGroupingResponseNode {
	highestPrivileges: IPrivilegeResponseNodes;
	highPrivileges: IPrivilegeResponseNodes;
	moderatePrivileges: IPrivilegeResponseNodes;
	lowPrivileges: IPrivilegeResponseNodes;
	lowDetections: ILatestDetectionDefinitionResponseNodes;
	moderateDetections: ILatestDetectionDefinitionResponseNodes;
	highDetections: ILatestDetectionDefinitionResponseNodes;
	criticalDetections: ILatestDetectionDefinitionResponseNodes;
}

const defaults = {
	highestPrivileges: null as List<IPrivilegeIdentity> | null,
	highPrivileges: null as List<IPrivilegeIdentity> | null,
	moderatePrivileges: null as List<IPrivilegeIdentity> | null,
	lowPrivileges: null as List<IPrivilegeIdentity> | null,
	lowDetections: null as List<InsightsDetectionModel> | null,
	moderateDetections: null as List<InsightsDetectionModel> | null,
	highDetections: null as List<InsightsDetectionModel> | null,
	criticalDetections: null as List<InsightsDetectionModel> | null
};

export class TenantInsightsGroupingModel extends Record<typeof defaults>(defaults) {
	static getDetections(detections: ILatestDetectionDefinitionResponseNode[], severityLevel: SeverityLevels) {
		const severity = mapSeverityLevelToSeverity(severityLevel);

		return List(
			detections.flatMap(latestDetectionDefinition =>
				this.convertToDetectionDefinitionToInsightsDetectionModel(
					latestDetectionDefinition.detectionDefinition,
					severity
				)
			)
		);
	}

	static getPrivilegeIdentities(privilegeIdentities: IPrivilegeResponseNode[], privilegeLevel: PrivilegeLevels) {
		const sensitivity = mapPrivilegeLevelToPrivilege(privilegeLevel);

		return privilegeIdentities
			.filter(privilegeIdentity => privilegeIdentity.identity.primaryEmail)
			.map(privilegeIdentity => ({
				id: privilegeIdentity.identity.id,
				email: privilegeIdentity.identity.primaryEmail,
				sensitivity
			}));
	}

	static convertToDetectionDefinitionToInsightsDetectionModel(
		definition: IDetectionDefinitionResponseNode,
		severity: number
	) {
		return definition.insights
			.filter(insight => insight.impactedEntities?.length)
			.flatMap(insight =>
				insight.impactedEntities!.map(
					entity =>
						new InsightsDetectionModel({
							id: definition.id,
							insightId: insight.id,
							email: entity.primaryEmail,
							severity,
							summary: definition.summary,
							metadata: definition.metadata
						})
				)
			);
	}

	static fromServerData(data: IInsightsDetectionsGroupingResponseNode): TenantInsightsGroupingModel {
		const {
			highestPrivileges: highestPrivilegesResponse,
			highPrivileges: highPrivilegesResponse,
			moderatePrivileges: moderatePrivilegesResponse,
			lowPrivileges: lowPrivilegesResponse,
			lowDetections: lowDetectionsResponse,
			moderateDetections: moderateDetectionsResponse,
			highDetections: highDetectionsResponse,
			criticalDetections: criticalDetectionsResponse
		} = data;

		const {
			highestPrivileges,
			highPrivileges,
			moderatePrivileges,
			lowPrivileges,
			lowDetections,
			moderateDetections,
			highDetections,
			criticalDetections
		} = {
			highestPrivileges: highestPrivilegesResponse?.nodes
				? this.getPrivilegeIdentities(highestPrivilegesResponse.nodes, PrivilegeLevels.HIGHEST)
				: null,
			highPrivileges: highPrivilegesResponse?.nodes
				? this.getPrivilegeIdentities(highPrivilegesResponse.nodes, PrivilegeLevels.HIGH)
				: null,
			moderatePrivileges: moderatePrivilegesResponse?.nodes
				? this.getPrivilegeIdentities(moderatePrivilegesResponse.nodes, PrivilegeLevels.MODERATE)
				: null,
			lowPrivileges: lowPrivilegesResponse?.nodes
				? this.getPrivilegeIdentities(lowPrivilegesResponse.nodes, PrivilegeLevels.LOW)
				: null,
			lowDetections: lowDetectionsResponse?.nodes
				? this.getDetections(lowDetectionsResponse.nodes, SeverityLevels.LOW)
				: null,
			moderateDetections: moderateDetectionsResponse?.nodes
				? this.getDetections(moderateDetectionsResponse.nodes, SeverityLevels.MODERATE)
				: null,
			highDetections: highDetectionsResponse?.nodes
				? this.getDetections(highDetectionsResponse.nodes, SeverityLevels.HIGH)
				: null,
			criticalDetections: criticalDetectionsResponse?.nodes
				? this.getDetections(criticalDetectionsResponse.nodes, SeverityLevels.CRITICAL)
				: null
		} as typeof defaults;

		return new TenantInsightsGroupingModel({
			highestPrivileges,
			highPrivileges,
			moderatePrivileges,
			lowPrivileges,
			lowDetections,
			moderateDetections,
			highDetections,
			criticalDetections
		});
	}
}
