import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "components/common/Link";
import { SensitivityIcon } from "components/ui/Icons/SensitivityIcon";
import { Typography } from "components/ui/Typography";
import { compiledClientConfig } from "config";
import { getSensitivityLabel, mapFromPrivilegeLevels, PrivilegeLevels } from "context/insightsContext";
import { getMaxEnumValue } from "utils/enums";
import { useStyles } from "./styles";
import { InsightsIndicator, type TInsightsIndicator } from "../InsightsIndicator";
import { InsightsIndicatorTitle } from "../InsightsIndicatorTitle";
import type { TSensitivity } from "context/insightsContext";

type TProps = Omit<TInsightsIndicator, "Icon" | "level"> & {
	sensitivity?: number;
	identityId?: string;
};

const {
	insights: { identitiesEndpoint },
	spogHost
} = compiledClientConfig;

export const getSensitivityColorFromLabel = (label: TSensitivity | null) => {
	if (!label) return "grey";
	return label === "high" ? "red" : label === "medium" ? "orange" : "grey";
};

const getSensitivityColor = (sensitivity?: number) => {
	if (!sensitivity) return "grey";
	const label = getSensitivityLabel(sensitivity);
	return getSensitivityColorFromLabel(label);
};

export const SensitivityIndicator: FC<TProps> = props => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "shared.insights" });
	const { sensitivity, identityId } = props;
	const color = props.color || getSensitivityColor(sensitivity);
	const level = mapFromPrivilegeLevels(sensitivity);

	const link = identityId ? `${spogHost}${identitiesEndpoint}/${identityId}` : "";

	const tooltipContent = useMemo(() => {
		const totalLevels = getMaxEnumValue(PrivilegeLevels);

		return (
			<div className={classes.tooltip}>
				<InsightsIndicatorTitle
					IndicatorComponent={SensitivityIndicator}
					level={level}
					color={color}
					text={sensitivity ? `${t("sensitivity")} - ${t(getSensitivityLabel(sensitivity)!).toLowerCase()}` : ""}
					totalLevels={totalLevels}
				/>
				{link ? (
					<Typography variant="text_title_sb" noWrap>
						<Link to={link} className={classes.link} target="_blank" rel="noopener noreferrer" noDecoration external>
							{t("identityLink")}
						</Link>
					</Typography>
				) : null}
			</div>
		);
	}, [classes.link, classes.tooltip, color, level, link, sensitivity, t]);

	return (
		<InsightsIndicator Icon={SensitivityIcon} level={level} color={color} tooltipContent={tooltipContent} {...props} />
	);
};
