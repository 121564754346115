import { useCallback, useMemo, useState } from "react";
import useMountState from "./useMountState";

interface ILoadingState {
	isLoading: boolean;
	withLoader: <T>(promise: Promise<T> | T) => Promise<T>;
	error: Error | null;
}

export const useLoadingState = (): ILoadingState => {
	const [activeLoaders, setActiveLoaders] = useState(0);
	const [error, setError] = useState<Error | null>(null);
	const [isMounted] = useMountState();

	const withLoader = useCallback(
		async <T>(promise: Promise<T> | T): Promise<T> => {
			try {
				if (isMounted()) {
					setActiveLoaders(current => current + 1);
					setError(null);
				}

				const result = await promise;
				return result;
			} catch (err) {
				if (isMounted()) setError(err as Error);
				throw err;
			} finally {
				if (isMounted()) setActiveLoaders(current => current - 1);
			}
		},
		[isMounted]
	);

	const isLoading = useMemo(() => activeLoaders > 0, [activeLoaders]);

	return useMemo(
		() => ({
			isLoading,
			error,
			withLoader
		}),
		[withLoader, isLoading, error]
	);
};
