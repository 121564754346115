export const removeRedundantSpaces = (str: string) => str?.replace(/\s\s+/g, " ").trim();

export const escapeJson = (json: string) => {
	return json
		.replace(/\\n/g, "\\n")
		.replace(/\\'/g, "\\'")
		.replace(/\\"/g, '\\"')
		.replace(/\\&/g, "\\&")
		.replace(/\\r/g, "\\r")
		.replace(/\\t/g, "\\t")
		.replace(/\\b/g, "\\b")
		.replace(/\\f/g, "\\f");
};

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const isUUID = (text?: string) => {
	if (!text) return false;
	return UUID_REGEX.test(text);
};

export const isStringArray = (value: unknown[]): value is string[] => {
	return value.length === 0 || (!!value?.[0] && typeof value[0] === "string");
};
