import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IconPrefix } from "components/ui/IconPrefix";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { BasicRoleBar, type TRole } from "./BasicRoleBar";
import { useStyles } from "./styles";
import type { TTooltipProps } from "components/ui/Tooltip";

export const RoleBarTooltip: FC<{
	role: TRole;
	tooltipTitle?: string;
	Icon?: IconComponent;
	linkable?: boolean;
	children: TTooltipProps["children"];
	delayShow?: number;
}> = ({ className, delayShow, innerRef, role, tooltipTitle, Icon, linkable, children }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const tooltipContent = useMemo(
		() => <BasicRoleBar role={role} linkable={linkable} noInteractions />,
		[linkable, role]
	);

	const tooltipHeader = useMemo(
		() => (
			<div className={classes.tooltipTitle}>
				<IconPrefix semibold Icon={Icon || RoleIcon} content={tooltipTitle || t("shared.role")} />
			</div>
		),
		[Icon, classes.tooltipTitle, t, tooltipTitle]
	);

	if (!children) return null;

	return (
		<TitleTooltip
			delayShow={delayShow}
			className={classNames(classes.tooltip, className)}
			innerRef={innerRef}
			header={tooltipHeader}
			body={tooltipContent}
			placement="top">
			{children}
		</TitleTooltip>
	);
};
