import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { RiskIndicator } from "components/common/insights/RiskIndicator";
import { SensitivityIndicator } from "components/common/insights/SensitivityIndicator";
import { Chip } from "components/ui/chips/Chip";
import { Typography } from "components/ui/Typography";
import { useStyles } from "./styles";
import type { TInsightsIndicatorIconColor } from "../InsightsIndicator";

export const InsightsIndicatorTitle: FC<{
	IndicatorComponent: typeof RiskIndicator | typeof SensitivityIndicator;
	level: number;
	text: string;
	color: TInsightsIndicatorIconColor;
	totalLevels: number;
	amount?: number;
}> = ({ color, level, text, amount, totalLevels, IndicatorComponent, className }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classNames(classes.tooltipTitle, className)}>
			<IndicatorComponent color={color} size={16} />
			<div className={classes.riskRatio}>
				<Typography variant="body_sb">
					{text} {level}/{totalLevels}
				</Typography>
			</div>
			{amount ? (
				<Chip size="small" readonly>
					{t("number", { value: amount })}
				</Chip>
			) : null}
		</div>
	);
};
