import classNames from "classnames";
import { Map } from "immutable";
import React, { useMemo } from "react";
import { useStyles } from "./styles";
import { TooltipOnOverflow } from "../TooltipOnOverflow";
import { Typography, type TTypographyVariant } from "../Typography";

export type TIconPrefixSize = "tiny" | "small" | "medium" | "large" | "huge";

export type TIconPrefixProps = {
	Icon?: IconComponent;
	content: string | number;
	disabled?: boolean;
	onClick?: () => void;
	semibold?: boolean;
	size?: TIconPrefixSize;
	contentClassName?: string;
	tooltipOnOverflow?: boolean;
};

const ICON_SIZE_MAP = Map<TIconPrefixSize, number>({
	tiny: 16,
	small: 16,
	medium: 20,
	large: 24,
	huge: 28
});

const REGULAR_TEXT_VARIANT_MAP = Map<TIconPrefixSize, TTypographyVariant>({
	tiny: "text_sm_reg",
	small: "text_reg",
	medium: "body_reg",
	large: "title_med",
	huge: "title_med"
});

const SEMIBOLD_TEXT_VARIANT_MAP = Map<TIconPrefixSize, TTypographyVariant>({
	tiny: "text_sm_sb",
	small: "text_title_sb",
	medium: "body_sb",
	large: "title_sb",
	huge: "h2_sb"
});

export const IconPrefix: FC<TIconPrefixProps> = ({
	Icon,
	className,
	contentClassName,
	content,
	disabled = false,
	innerRef,
	onClick,
	semibold,
	size = "medium",
	tooltipOnOverflow = false
}) => {
	const classes = useStyles({ size, disabled });

	const [iconSize, textVariant]: [number, TTypographyVariant] = useMemo(() => {
		const variantMap = semibold ? SEMIBOLD_TEXT_VARIANT_MAP : REGULAR_TEXT_VARIANT_MAP;
		return [ICON_SIZE_MAP.get(size, 20), variantMap.get(size, "body_reg")];
	}, [size, semibold]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef} onClick={onClick}>
			{Icon ? <Icon size={iconSize} /> : null}
			{tooltipOnOverflow ? (
				<TooltipOnOverflow content={content} textVariant={textVariant} className={contentClassName} />
			) : (
				<Typography noWrap variant={textVariant} className={contentClassName}>
					{content}
				</Typography>
			)}
		</div>
	);
};
