import classNames from "classnames";
import React, { useCallback, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { Divider } from "components/ui/Divider";
import { IconPrefix } from "components/ui/IconPrefix";
import { SearchIcon } from "components/ui/Icons/SearchIcon";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Typography } from "components/ui/Typography";
import { useSystemOverlays } from "context/overlaysContext";
import {
	getGroups,
	getOptionKey as utilsGetOptionKey,
	type TOptionRenderer,
	type TRenderOptionProps
} from "utils/ui/select";
import { useStyles } from "./styles";
import type { usePopperTooltip } from "react-popper-tooltip";

export type TGrouping = string | { label: string; key: string; order: number };
export interface ISelectItemListProps<T> {
	highlightedIndex: number;
	isSelectedControlled?: boolean;
	filteredOptions: T[];
	getIconForGroup?: (groupName: string) => IconComponent | undefined;
	getOptionKey?: (option: T) => string;
	getOptionLabel?: (option: T) => string;
	getTooltipProps: ReturnType<typeof usePopperTooltip>["getTooltipProps"];
	groupBy?: (option: T) => TGrouping;
	hasMore: boolean;
	inputValue?: string | null;
	isOptionEqualToValue?: (option: T, value: T) => boolean;
	loading?: boolean;
	optionsListClassName?: string;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	renderOption: TOptionRenderer<T>;
	shouldDisableOption?: (value: T) => boolean;
	setTooltipRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
	value?: T[] | T | null;
	input?: React.ReactNode;
}

const ListOption = <T,>({
	getOptionKey,
	getOptionLabel,
	getIsSelected,
	highlightedIndex,
	inputValue,
	onSelect,
	option,
	renderOption,
	rowIndex,
	shouldDisableOption
}: TProps<{
	getOptionKey: NonNullable<ISelectItemListProps<T>["getOptionKey"]>;
	getOptionLabel: ISelectItemListProps<T>["getOptionLabel"];
	getIsSelected: (option: T) => boolean;
	highlightedIndex: number;
	inputValue?: string | null;
	onSelect: ISelectItemListProps<T>["onSelect"];
	option: T;
	renderOption: TOptionRenderer<T>;
	rowIndex: number;
	shouldDisableOption?: (value: T) => boolean;
}>) => {
	const optionKey = getOptionKey(option);
	const disabled = shouldDisableOption?.(option) ?? false;
	const isSelected = getIsSelected(option);
	const isHighlighted = highlightedIndex === rowIndex;
	if ("isSelectOption" in renderOption && renderOption.isSelectOption) {
		const OptionComp = renderOption as FC<TRenderOptionProps<T>>;
		return (
			<OptionComp
				disabled={disabled}
				isHighlighted={isHighlighted}
				getTextContent={getOptionLabel}
				inputValue={inputValue}
				isSelected={isSelected}
				key={optionKey}
				onSelect={onSelect}
				option={option}
				optionKey={optionKey}
			/>
		);
	}
	return renderOption({
		disabled,
		getTextContent: getOptionLabel,
		inputValue,
		isHighlighted,
		isSelected,
		onSelect,
		option,
		optionKey
	});
};

export function SelectItemList<T>(props: TProps<ISelectItemListProps<T>>) {
	const {
		className,
		highlightedIndex,
		filteredOptions,
		getIconForGroup,
		getTooltipProps,
		getOptionKey: propGetOptionKey,
		getOptionLabel,
		groupBy,
		hasMore,
		input,
		inputValue,
		isOptionEqualToValue,
		isSelectedControlled,
		loading,
		optionsListClassName,
		onSelect,
		renderOption,
		shouldDisableOption,
		setTooltipRef,
		value
	} = props;
	const ref = useRef<HTMLDivElement>(null);

	const classes = useStyles();
	const { t } = useTranslation();
	const systemOverlays = useSystemOverlays();

	const getOptionKey = useCallback(
		(option: T) => {
			const key = propGetOptionKey ? propGetOptionKey(option) : utilsGetOptionKey(option, getOptionLabel);
			return key;
		},
		[getOptionLabel, propGetOptionKey]
	);

	const searchForMore = useMemo(
		() =>
			hasMore ? (
				<IconPrefix
					Icon={SearchIcon}
					className={classes.searchForMore}
					content={t("common.select.searchForMore")}
					size="tiny"
				/>
			) : null,
		[classes.searchForMore, hasMore, t]
	);

	const groups = useMemo(
		() => (groupBy && filteredOptions && filteredOptions.length ? getGroups(filteredOptions, groupBy) : null),
		[filteredOptions, groupBy]
	);

	const getIsSelected = useCallback(
		(option: T) => {
			const isEqualToValue =
				isOptionEqualToValue &&
				(isSelectedControlled || value) &&
				(Array.isArray(value)
					? value?.some(val => isOptionEqualToValue(option, val))
					: isOptionEqualToValue(option, value!));

			return option && !!isEqualToValue;
		},
		[isOptionEqualToValue, isSelectedControlled, value]
	);

	const hasNoResults = !filteredOptions?.length;
	const listContent = useMemo(() => {
		if (loading) {
			return <LoadingSpinner />;
		} else if (hasNoResults) {
			return <Typography variant="body_reg">{t("common.select.noOptionsFound")}</Typography>;
		} else if (groups) {
			const groupNames: string[] = Array.from(groups.keys());
			let rowIndex = 0;
			return (
				<div className={classNames(classes.optionsListOverflow, optionsListClassName)}>
					{groupNames.map((groupName, index) => {
						const options = groups.get(groupName)?.values;
						const label = groups.get(groupName)?.label;
						const header = label && (label.endsWith(":") ? label.replace(":", "") : label);
						const Icon = getIconForGroup?.(groupName);
						const shouldShowDivider = index < groupNames.length - 1;
						return (
							<div key={groupName || "NO_GROUP"} className={classes.groupContainer}>
								{header ? (
									<IconPrefix Icon={Icon} content={header.replace(":", "")} semibold className={classes.groupLabel} />
								) : null}
								{options?.map(option => (
									<ListOption
										key={getOptionKey(option)}
										getOptionKey={getOptionKey}
										getOptionLabel={getOptionLabel}
										getIsSelected={getIsSelected}
										highlightedIndex={highlightedIndex}
										inputValue={inputValue}
										onSelect={onSelect}
										option={option}
										renderOption={renderOption}
										rowIndex={rowIndex++}
										shouldDisableOption={shouldDisableOption}
									/>
								))}
								{shouldShowDivider && <Divider horizontal color="var(--color-grey-600" />}
							</div>
						);
					})}
					{searchForMore}
				</div>
			);
		} else {
			return (
				<div className={classes.optionsListOverflow}>
					{filteredOptions.map((option, index) => (
						<ListOption
							key={getOptionKey(option)}
							getOptionKey={getOptionKey}
							getOptionLabel={getOptionLabel}
							getIsSelected={getIsSelected}
							highlightedIndex={highlightedIndex}
							inputValue={inputValue}
							onSelect={onSelect}
							option={option}
							renderOption={renderOption}
							rowIndex={index}
							shouldDisableOption={shouldDisableOption}
						/>
					))}
					{searchForMore}
				</div>
			);
		}
	}, [
		loading,
		hasNoResults,
		groups,
		t,
		classes.optionsListOverflow,
		classes.groupContainer,
		classes.groupLabel,
		optionsListClassName,
		searchForMore,
		getIconForGroup,
		getOptionKey,
		getOptionLabel,
		getIsSelected,
		highlightedIndex,
		inputValue,
		onSelect,
		renderOption,
		shouldDisableOption,
		filteredOptions
	]);

	return createPortal(
		<div ref={setTooltipRef} {...getTooltipProps()} className={classNames(classes.selectItemsContainer, className)}>
			<div ref={ref} className={classes.optionsContainer}>
				{input ? <div className={classes.searchBarContainer}>{input}</div> : null}
				<div
					className={classNames(classes.optionsListContainer, {
						[classes.messageListContent]: loading || hasNoResults,
						[classes.noResults]: hasNoResults,
						[classes.loading]: loading
					})}>
					{listContent}
				</div>
			</div>
		</div>,
		systemOverlays ?? document.body
	);
}
