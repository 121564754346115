import { createUseStyles } from "react-jss";

export const INSIGHT_INDICATOR_RED = "var(--color-red-700)";
export const INSIGHTS_INDICATOR_ORANGE = "var(--color-orange-500)";
export const INSIGHTS_INDICATOR_GREY = "var(--color-grey-500)";

export const useStyles = createUseStyles({
	tooltipClassName: {
		minWidth: "100px",
		maxWidth: "600px",
		maxHeight: "200px",
		overflowY: "scroll",
		alignItems: "flex-start",
		padding: "var(--spacing-x3, 12px)"
	},
	riskIndicator: {
		position: "relative",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	level: {
		position: "absolute",
		color: "var(--color-white)",
		marginTop: "2px",
		"&$increasedMargin": {
			marginTop: "var(--spacing-x1, 4px)"
		}
	},
	red: {
		color: INSIGHT_INDICATOR_RED
	},
	orange: {
		color: INSIGHTS_INDICATOR_ORANGE
	},
	grey: {
		color: INSIGHTS_INDICATOR_GREY
	},
	increasedMargin: {}
});
