import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	image: {
		height: "100%",
		width: "100%",
		borderRadius: "50%",
		fontSize: "32px"
	},
	nameEmailDetectionsWrapper: {
		display: "flex",
		alignItems: "center",
		overflow: "hidden"
	}
});
