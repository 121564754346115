import React from "react";
import { RiskIndicator } from "components/common/insights/RiskIndicator";
import { SensitivityIndicator } from "components/common/insights/SensitivityIndicator";
import { useStyles } from "./styles";
import type { TUserInsightsDataModel } from "models/UserModel";

export const InsightsIndicators: FC<{ insightsData: TUserInsightsDataModel }> = ({ insightsData }) => {
	const classes = useStyles();
	const { maxSeverity = 0, sensitivity = 0, detections } = insightsData;

	return (
		<div className={classes.insightsIndicator}>
			{sensitivity ? (
				<SensitivityIndicator sensitivity={sensitivity} identityId={insightsData.identityId} size={24} />
			) : null}
			{maxSeverity ? <RiskIndicator maxSeverity={maxSeverity} detections={detections} size={24} /> : null}
		</div>
	);
};
