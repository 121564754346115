import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	tooltipContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "20px",
		padding: "12px",
		width: "100%"
	}
});
