import classNames from "classnames";
import { Map } from "immutable";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserNode } from "components/common/entities/Nodes/UserNode";
import { StaticChip } from "components/ui/chips/StaticChip";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { DirectoryGroupCard, ApprovalGroupCard, OnCallGroupCard } from "./ApprovalGroupCard";
import { ApproverWebhookChip } from "./ApproverWebhookChip";
import { MaintainerCard } from "./MaintainersCard";
import { OwnerCard } from "./OwnersCard";
import { useStyles } from "./styles";
import type { TEntityNodeColor } from "components/common/entities/Nodes/EntityNode";
import type { TFullApprovalRequest } from "components/common/RequestDetails/types";
import type { TicketApprovalEntityModel } from "models/TicketApprovalEntityModel";
import type { TTicketApprovalResponseType } from "models/TicketApprovalResponseModel";

interface IProps {
	responsesByUserId: Map<string, TTicketApprovalResponseType>;
	entity: TicketApprovalEntityModel;
	request?: TFullApprovalRequest;
	approveable?: boolean;
}

export const ApprovalStepEntity: FC<IProps> = ({ entity, responsesByUserId, request, approveable = false }) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "common.requestDetails.approvalProcess.step" });
	const approverIds = useMemo(() => entity.approverIds, [entity]);

	const { responderIds, status, disabled } = useMemo(() => {
		const responderIds = approverIds.filter(id => responsesByUserId.has(id));
		const responses = responderIds.size ? responderIds.map(id => responsesByUserId.get(id)!) : undefined;

		if (!responses?.size)
			return { status: "pending" as const, disabled: request?.status === "approved" && request?.operator === "or" };

		if (responses.includes("decline") || responses.includes("adminDecline"))
			return { status: "declined" as const, responderIds, disabled: false };
		return { status: "approved" as const, responderIds, disabled: false };
	}, [approverIds, request?.operator, request?.status, responsesByUserId]);

	const cardClass = classNames(classes.cardContainer, { [classes.disabled]: disabled });
	let color: TEntityNodeColor = "purple";
	const userId = entity.approverIds.get(0) ?? "";

	switch (entity.type) {
		case "Automatic":
			return (
				<StaticChip variant="green" size="large" SuffixIcon={GrantedIcon}>
					{t("automaticApproval")}
				</StaticChip>
			);
		case "User":
		case "DirectManager":
			if (status === "approved" && approveable) color = "green";
			if (status === "declined" && approveable) color = "red";

			if (entity.approverIds.size > 1) {
				break;
			}

			return (
				<UserNode
					user={userId}
					className={cardClass}
					color={color}
					selected={approveable && responderIds?.includes(userId)}
				/>
			);
		case "DirectoryGroup":
			return (
				<DirectoryGroupCard
					status={status}
					className={cardClass}
					id={entity.identifier!}
					responderIds={approveable ? responderIds : undefined}
					name={entity.displayName}
					approverIds={approveable ? approverIds : undefined}
				/>
			);
		case "OnCallIntegrationSchedule":
			return (
				<OnCallGroupCard
					status={status}
					className={cardClass}
					id={entity.identifier!}
					responderIds={responderIds}
					name={entity.displayName}
					approverIds={approverIds}
				/>
			);
		case "IntegrationMaintainer":
		case "ResourceMaintainer":
			if (entity.approverIds.size > 1) {
				break;
			}

			return (
				<MaintainerCard
					approverIds={approverIds}
					status={status}
					responderIds={responderIds}
					className={cardClass}
					type={entity.type}
				/>
			);
		case "IntegrationOwner":
		case "ResourceOwner":
			if (entity.approverIds.size > 1) {
				break;
			}

			return (
				<OwnerCard
					approverIds={approverIds}
					status={status}
					responderIds={responderIds}
					className={cardClass}
					type={entity.type}
				/>
			);
		case "Webhook":
			return entity.approverIds.size ? (
				<ApprovalGroupCard
					className={cardClass}
					approverIds={approverIds}
					status={status}
					responderIds={responderIds}
					name={entity.displayName}
				/>
			) : (
				<ApproverWebhookChip status={status} displayName={entity.displayName} />
			);
	}

	return (
		<ApprovalGroupCard
			className={cardClass}
			approverIds={approverIds}
			status={status}
			responderIds={responderIds}
			name={entity.displayName}
		/>
	);
};
