import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	tooltipTitle: {
		display: "flex"
	},
	riskRatio: {
		margin: "0 var(--spacing-x2, 8px)"
	}
});
