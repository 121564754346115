import constate from "constate";
import { Map } from "immutable";
import { useCallback, useState } from "react";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useLoadingState } from "hooks/useLoadingState";
import { AgentTokenModel } from "models/AgentTokenModel";
import {
	createAgentToken as apiCreateAgentToken,
	deleteAgentToken as apiDeleteAgentToken,
	getAgentTokens,
	updateAgentTokenName as apiUpdateAgentToken
} from "../api/agentTokens";

const useAgentTokens = () => {
	const [agentTokens, setAgentTokens] = useState<Map<string, AgentTokenModel> | null>(null);
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const { withLoader, isLoading } = useLoadingState();

	const loadAgentTokens = useCallback(async () => {
		let tokens: Map<string, AgentTokenModel>;
		try {
			tokens = await withLoader(getAgentTokens());
		} catch (error) {
			openGlobalErrorModal(error as Error);
			tokens = Map();
		}
		setAgentTokens(tokens);
		return tokens;
	}, [openGlobalErrorModal, withLoader]);

	const getMap = useCallback(async (): Promise<Map<string, AgentTokenModel>> => {
		if (agentTokens) return agentTokens;
		return loadAgentTokens();
	}, [agentTokens, loadAgentTokens]);

	const createAgentToken = useCallback(
		async (name: string) => {
			try {
				const token = await apiCreateAgentToken(name);
				setAgentTokens((await getMap()).set(token.id, token));
				return token;
			} catch (error) {
				openGlobalErrorModal(error as Error);
				return;
			}
		},
		[getMap, openGlobalErrorModal]
	);

	const updateAgentToken = useCallback(
		async (id: string, name: string) => {
			try {
				const token = await apiUpdateAgentToken(id, name);
				const updatedToken = (await getMap()).get(token.id)?.set("name", token.name);

				if (!updatedToken) return;
				setAgentTokens((await getMap()).set(token.id, updatedToken));

				return updatedToken;
			} catch (error) {
				openGlobalErrorModal(error as Error);
				return;
			}
		},
		[getMap, openGlobalErrorModal]
	);

	const deleteAgentToken = useCallback(
		async (tokenId: string) => {
			try {
				await apiDeleteAgentToken(tokenId);

				setAgentTokens((await getMap()).remove(tokenId));
			} catch (error) {
				openGlobalErrorModal(error as Error);
				return;
			}
		},
		[getMap, openGlobalErrorModal]
	);

	return {
		state: { agentTokens, isLoading },
		actions: { loadAgentTokens, createAgentToken, updateAgentToken, deleteAgentToken }
	};
};

export const [AgentTokensProvider, useAgentTokensContext] = constate(useAgentTokens);
