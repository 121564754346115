import constate from "constate";
import { Map } from "immutable";
import { useCallback, useMemo, useState } from "react";
import {
	createIntegration as apiCreateIntegration,
	deleteIntegration as apiDeleteIntegration,
	editIntegration,
	getIntegration,
	getIntegrations,
	IIntegrationCreationData,
	TIntegrationUpdateData
} from "api/integrations";
import { useFetchedState } from "hooks/useFetchedState";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { IntegrationModel } from "models/IntegrationModel";
import { removeRedundantSpaces } from "utils/strings";

const useIntegrations = () => {
	const {
		data: integrations,
		setData: setIntegrations,
		loadData: loadIntegrations,
		isLoading
	} = useFetchedState<[], Map<string, IntegrationModel>>(getIntegrations);
	const [fullyLoaded, setFullyLoaded] = useState(Map<string, boolean>());
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const setIntegration = useCallback(
		async (updatedIntegration: IntegrationModel) => {
			let integrationsOrEmptyMap = integrations;
			if (!integrations) {
				try {
					integrationsOrEmptyMap = await getIntegrations();
				} catch (error) {
					openGlobalErrorModal(error as Error);
					return;
				}
			}
			if (!integrationsOrEmptyMap) {
				integrationsOrEmptyMap = Map<string, IntegrationModel>();
			}

			if (updatedIntegration) {
				integrationsOrEmptyMap = integrationsOrEmptyMap.set(updatedIntegration.id, updatedIntegration);
			}

			setIntegrations(integrationsOrEmptyMap);
			return integrationsOrEmptyMap;
		},
		[integrations, openGlobalErrorModal, setIntegrations]
	);

	const createIntegration = useCallback(
		async (formData: IIntegrationCreationData) => {
			formData.name = removeRedundantSpaces(formData.name);
			const createdIntegrationModel = await apiCreateIntegration(formData);
			await setIntegration(createdIntegrationModel);
		},
		[setIntegration]
	);

	const updateIntegration = useCallback(
		async (formData: TIntegrationUpdateData) => {
			if (formData.name) {
				formData.name = removeRedundantSpaces(formData.name);
			}
			try {
				const updatedIntegrationModel = await editIntegration(formData);
				await setIntegration(updatedIntegrationModel);
				setFullyLoaded(current => current.set(updatedIntegrationModel.id, true));
			} catch (error) {
				openGlobalErrorModal(error as Error);
				return;
			}
		},
		[openGlobalErrorModal, setIntegration]
	);

	const loadIntegration = useCallback(
		async (integrationId: string) => {
			try {
				const integration = await getIntegration(integrationId);
				await setIntegration(integration);
				setFullyLoaded(current => current.set(integration.id, true));
				return integration;
			} catch (error) {
				openGlobalErrorModal(error as Error);
			}
			return;
		},
		[openGlobalErrorModal, setIntegration]
	);

	const deleteIntegration = useCallback(
		async (integrationId: string) => {
			try {
				const deletedIntegration = await apiDeleteIntegration(integrationId);
				await setIntegration(deletedIntegration);
				setFullyLoaded(current => current.remove(deletedIntegration.id));
			} catch (error) {
				openGlobalErrorModal(error as Error);
			}
		},
		[setIntegration, openGlobalErrorModal]
	);

	const existingIntegrations = useMemo(
		() => (integrations ? integrations.filter(integration => !integration.isDeleted) : null),
		[integrations]
	);

	return useMemo(
		() => ({
			state: { integrations, existingIntegrations, isLoading, fullyLoaded },
			actions: {
				setIntegration,
				loadIntegrations,
				createIntegration,
				updateIntegration,
				loadIntegration,
				deleteIntegration
			}
		}),
		[
			integrations,
			existingIntegrations,
			isLoading,
			fullyLoaded,
			setIntegration,
			loadIntegrations,
			createIntegration,
			updateIntegration,
			loadIntegration,
			deleteIntegration
		]
	);
};

export const [IntegrationsProvider, useIntegrationsContext] = constate(useIntegrations);
