import React, { useMemo } from "react";
import { Link } from "components/common/Link";
import { TitleBody } from "components/ui/TitleBody";
import { Tooltip } from "components/ui/Tooltip";
import { Typography } from "components/ui/Typography";
import { compiledClientConfig } from "config";
import { InsightsDetectionModel } from "models/InsightsDetectionModel";
import { useStyles } from "./styles";

const {
	insights: { detectionsEndpoint },
	spogHost
} = compiledClientConfig;
const DELAY_SHOW_TOOLTIP = 700;

export const DetectionSummary: FC<{
	detection: InsightsDetectionModel;
	level: number;
	totalLevels: number;
}> = ({ detection, level, totalLevels }) => {
	const classes = useStyles();

	const link =
		detection.id && detection.insightId
			? `${spogHost}${detectionsEndpoint}/${detection.id}/instance/${detection.insightId}`
			: "";

	const tooltipContent = useMemo(() => {
		const {
			summary,
			metadata: { detail, concern, remediation }
		} = detection;

		return (
			<div className={classes.detectionTooltip}>
				<TitleBody title={"Summary"} body={summary} size="small" />
				<TitleBody title={"Detail"} body={detail} size="small" />
				<TitleBody title={"Concern"} body={concern} size="small" />
				<TitleBody title={"Remediation"} body={remediation} size="small" />
			</div>
		);
	}, [classes.detectionTooltip, detection]);

	return (
		<div className={classes.detectionSummary}>
			<Tooltip placement="right" content={tooltipContent} delayShow={DELAY_SHOW_TOOLTIP}>
				<Typography variant="text_title_sb" noWrap>
					<Link to={link} className={classes.summary} target="_blank" rel="noopener noreferrer" noDecoration external>
						{detection.summary}
					</Link>
				</Typography>
			</Tooltip>
			<div className={classes.riskRatio}>
				<Typography variant="text_sm_reg">
					(<Typography variant="text_sm_sb">{level}</Typography>
					<span>/{totalLevels}</span>)
				</Typography>
			</div>
		</div>
	);
};
