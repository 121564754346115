import React from "react";
import { makeIcon } from "components/ui/Icon";

export const RiskIndicatorIcon = makeIcon(
	<svg viewBox="0 0 16 16" fill="none">
		<path
			d="M6.28501 2.35831C7.06182 1.06363 8.93818 1.06363 9.71499 2.35831L15.1826 11.471C15.9824 12.8041 15.0222 14.5 13.4676 14.5H2.53238C0.977792 14.5 0.0175651 12.8041 0.817394 11.471L6.28501 2.35831Z"
			fill="currentColor"
		/>
	</svg>
);
