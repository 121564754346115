import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	tooltipContent: {
		gap: "var(--spacing-x2, 8px)"
	},
	detectionsWrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		gap: "var(--spacing-x1, 4px)"
	},
	titleLevel: {
		marginLeft: "var(--spacing-x1, 4px)"
	}
});
