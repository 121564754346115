export const GET_IDENTITIES_DETECTIONS_QUERY = `
    query ($emails: [String]!, $limit: Int!, $endCursor: String) {
        detectionImpactedEntities(
            first: $limit
            after: $endCursor
            where: {
                and: [
                    {
                        or: [
                            { entity: { primaryEmail: { in: $emails } } }
                            { entity: { userName: { in: $emails } } }
                        ]
                    }
                    {
                        and: [
                            { and: [{ insight: { currentStatus: { in: [OPEN, IN_PROGRESS] } } }] }
                            { and: [] }
                        ]
                    }
                ]
            }
            order: [{ insight: { definition: { severity: DESC } } }]
        ) {
            nodes {
                insight {
                    id
                    definition {
                        id
                        severity
                        summary
                        metadata {
                            detail
                            concern
                            remediation
                        }
                    }
                }
                name
                id
                privilegeValue
                entity {
                    primaryEmail
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;

export const GET_IDENTITIES_QUERY = `
    query ($emails: [String]!, $limit: Int!) {
        identities(
            first: $limit
            where: {
                and: [
                    { identity: { primaryEmail: { in: $emails } } }
                    {
                        identity: {
                            associatedAccounts: {
                                all: {
                                    account: {
                                        detectionImpactedEntities: {
                                            all: { insight: { currentStatus: { in: [OPEN, IN_PROGRESS] } } }
                                        }
                                    }
                                }
                            }
                        }
                    }
                ]
            }
        ) {
            totalCount
            nodes {
                identity {
                    id
                    primaryEmail
                    truePrivilegeValue
                    associatedAccounts {
                        account {
                            id
                            primaryEmail
                            detectionImpactedEntities {
                                insight {
                                    id
                                    currentStatus
                                    definition {
                                        id
                                        severity
                                        summary
                                        metadata {
                                            detail
                                            concern
                                            remediation
                                        }
                                    }
                                }
                                name
                                id
                                privilegeValue
                                entity {
                                    primaryEmail
                                }
                            }
                        }
                    }
                }
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;

// Getting impactedEntities from detectionDefinition has huge performance impact
const TENANT_DETECTION_DEFINITION = `
    detectionDefinition {
                id
                summary
                metadata {
                    detail
                    concern
                    remediation
                }
                insights {
                    id
                    impactedEntities {
                        id
                        primaryEmail
                    }
                }
            }
`;

export const GET_INSIGHTS_TENANT_QUERY = `
        query(
            $limit: Int
            $lowSevFilter: IntOperationFilterInput
            $modSevFilter: IntOperationFilterInput
            $highSevFilter: IntOperationFilterInput
            $critSevFilter: IntOperationFilterInput
      ) {
            highestPrivileges: identities(
                first: $limit
                where: { identity: { truePrivilegeValue: { gt: 9 } } }
            ) {
                nodes {
                    identity {
                        id
                        primaryEmail
                    }
                }
                totalCount
            }
            highPrivileges: identities(
                first: $limit
                where: {
                identity: { truePrivilegeValue: { lte: 9, gt: 6 } }
                }
            ) {
                nodes {
                    identity {
                        id
                        primaryEmail
                    }
                }
                totalCount
            }
            moderatePrivileges: identities(
                first: $limit
                where: {
                identity: { truePrivilegeValue: { lte: 6, gt: 3 } }
                }
            ) {
                nodes {
                    identity {
                        id
                        primaryEmail
                    }
                }
                totalCount
            }
            lowPrivileges: identities(
                first: $limit
                where: {
                identity: { truePrivilegeValue: { lte: 3, gt: 0 } }
                }
            ) {
                nodes {
                    identity {
                        id
                        primaryEmail
                    }
                }
                totalCount
            }
     
        lowDetections: latestDetectionDefinitions(
          first: $limit
          where: { detectionDefinition: { severity: $lowSevFilter } }
        ) {
          nodes {
            openDetectionCount
            ${TENANT_DETECTION_DEFINITION}
          }
        }
        moderateDetections: latestDetectionDefinitions(
          first: $limit
          where: { detectionDefinition: { severity: $modSevFilter } }
        ) {
          nodes {
            openDetectionCount
            ${TENANT_DETECTION_DEFINITION}
          }
        }
        highDetections: latestDetectionDefinitions(
          first: $limit
          where: { detectionDefinition: { severity: $highSevFilter } }
        ) {
          nodes {
            openDetectionCount
            ${TENANT_DETECTION_DEFINITION}
          }
        }
        criticalDetections: latestDetectionDefinitions(
          first: $limit
          where: { detectionDefinition: { severity: $critSevFilter } }
        ) {
          nodes {
            openDetectionCount
            ${TENANT_DETECTION_DEFINITION}
          }
        }
    }
`;
