import { compiledClientConfig } from "config";
import { devLog } from "utils/devtools/devLogging";
import { requirePropertyOf } from "utils/security";
import { graphqlReq } from "./graphqlReq";

interface IInsightsPageInfo {
	startCursor: string;
	endCursor: string;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
}

interface IInsightsResults {
	nodes: unknown[];
	pageInfo: IInsightsPageInfo;
}

type TVariables = object & { limit?: number; endCursor?: string | null };

const PAGE_SIZE = 1000;
const { graphqlEndpoint } = compiledClientConfig.insights;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const graphqlInsightsReq = async (options: { query: string; variables: TVariables }): Promise<any> => {
	if (!graphqlEndpoint) {
		devLog({ level: "error", message: "Insights graph endpoint not found" });
		return;
	}
	const { query, variables } = options;

	if (!variables.limit) {
		variables.limit = PAGE_SIZE;
	}

	return graphqlReq(graphqlEndpoint, { query, variables });
};

// Insights data is limited to 1000 items per request, so we need to paginate through all the data
export const graphqlGetAllInsightsPages = async (options: { query: string; variables: object }) => {
	let allData: unknown[] = [];
	let endCursor = null;
	const { query, variables } = options;

	while (true) {
		const { data } = await graphqlInsightsReq({ query, variables: { ...variables, limit: PAGE_SIZE, endCursor } });
		const dataKey = Object.keys(data)[0];
		if (!dataKey) {
			throw new Error("Insights fetch error - no data key found");
		}

		const { nodes, pageInfo } = requirePropertyOf(data, dataKey) as IInsightsResults;
		allData = allData.concat(nodes);

		if (!pageInfo.hasNextPage) {
			break;
		}
		endCursor = pageInfo.endCursor;
	}

	return allData;
};
