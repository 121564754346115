import { List, Record } from "immutable";
import { type IInsightsDetectionResponseNode, InsightsDetectionModel } from "./InsightsDetectionModel";

interface IInsightsAccountResponseNode {
	account: {
		id: string;
		primaryEmail: string;
		detectionImpactedEntities: IInsightsDetectionResponseNode[];
	};
}

export interface IInsightsIdentityResponseNode {
	identity: {
		id: string;
		primaryEmail: string;
		truePrivilegeValue: number | null;
		associatedAccounts: IInsightsAccountResponseNode[];
	};
}

interface IInsightsAccount {
	id: string;
	email: string;
	detections: List<InsightsDetectionModel>;
}

const defaults = {
	id: "",
	email: "",
	sensitivity: 0,
	accounts: null as List<IInsightsAccount> | null
};

export class InsightsIdentityModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: IInsightsIdentityResponseNode): InsightsIdentityModel {
		const { identity } = data;
		const { primaryEmail, truePrivilegeValue, associatedAccounts } = identity;

		const { id, email, sensitivity, accounts } = {
			id: identity.id,
			email: primaryEmail,
			sensitivity: truePrivilegeValue || 0,
			accounts: associatedAccounts
				? List<IInsightsAccount>(
						associatedAccounts.map(associatedAccount => {
							const {
								account: { id, primaryEmail, detectionImpactedEntities }
							} = associatedAccount;
							return {
								id,
								email: primaryEmail,
								detections: List(detectionImpactedEntities.map(InsightsDetectionModel.fromServerData))
							};
						})
					)
				: null
		} as typeof defaults;

		return new InsightsIdentityModel({
			id,
			email,
			sensitivity,
			accounts
		});
	}
}
