import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "hooks/useUser";
import { UserModel } from "models/UserModel";
import { useStyles } from "./styles";
import { InsightsIndicators } from "../../components/indicators/Insights";
import { NameWithEmail } from "../../components/NameWithEmail";
import { EntityNode, TEntityNodeColor } from "../EntityNode";

export type TBasePropsUserNode = {
	withInsights?: boolean;
	actions?: React.ReactNode;
	color?: TEntityNodeColor;
	delayShowTooltip?: number;
	fixedWidth?: boolean;
	icon?: React.ReactNode;
	isDeleted?: boolean;
	onDelete?: () => void;
	selected?: boolean;
	highlighted?: boolean;
};

type TPropsUserNode = TBasePropsUserNode & {
	user: string | UserModel;
	refSetter?: (ids: string[]) => (element: unknown) => void;
};

const getRef = (props: TProps<TPropsUserNode>) => {
	if (typeof props.user === "string") {
		const ids = [props.user];
		return props.refSetter ? props.refSetter(ids) : props.innerRef;
	}
	return props.innerRef;
};

export const USER_NODE_DEFAULT_COLOR: TEntityNodeColor = "purple";
export const USER_NODE_BORDER_RADIUS_PX = 32;

export const UserNode: FC<TPropsUserNode> = props => {
	const { user: propUser, highlighted, fixedWidth, className, withInsights, delayShowTooltip, selected } = props;
	const ref = getRef(props);
	const classes = useStyles();
	const { t } = useTranslation();
	const fetchedUser = useUser(typeof propUser === "string" ? propUser : "");
	const user = useMemo(() => {
		if (!propUser) return null;
		if (propUser instanceof UserModel) {
			return propUser;
		}
		return fetchedUser;
	}, [propUser, fetchedUser]);

	const userImageElement = useMemo(
		() => (user?.imageUrl ? <img src={user.imageUrl} className={classes.image} /> : null),
		[classes.image, user?.imageUrl]
	);
	const isDeleted = props.isDeleted || user?.isDeleted;

	const { content, actions } = useMemo(() => {
		return {
			content: user ? (
				<div className={classes.nameEmailDetectionsWrapper}>
					<NameWithEmail delayShowTooltip={delayShowTooltip} name={user.fullName} email={user.email} />
					{withInsights && user.insightsData && <InsightsIndicators insightsData={user.insightsData} />}
				</div>
			) : (
				t("common.unknown.user")
			),
			actions: null
		};
	}, [t, user, classes.nameEmailDetectionsWrapper, delayShowTooltip, withInsights]);

	return (
		<EntityNode
			className={className}
			borderRadius={USER_NODE_BORDER_RADIUS_PX}
			color={props.color || USER_NODE_DEFAULT_COLOR}
			icon={userImageElement}
			content={content}
			actions={actions}
			isDeleted={isDeleted}
			selected={selected}
			highlighted={highlighted}
			fixedWidth={fixedWidth}
			innerRef={ref}
			delayShowTooltip={delayShowTooltip}
		/>
	);
};
