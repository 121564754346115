import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TitleBody } from "components/ui/TitleBody";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { Typography } from "components/ui/Typography";
import { useStyles } from "./styles";
import { IntegrationImage } from "../../IntegrationImage";
import { Card, TCardProps } from "../Card";
import { ResourceAndRoleCountCardBody, TResourceAndRoleCountCardBodyProps } from "../ResourceAndRoleCountCardBody";
import type { IntegrationModel } from "models/IntegrationModel";

type TIntegrationCardProps = Omit<TCardProps, "header" | "content"> & {
	disabled?: boolean;
} & TResourceAndRoleCountCardBodyProps & {
		integration: IntegrationModel;
	};

export const IntegrationCard: FC<TIntegrationCardProps> = props => {
	const { integration, resourcesCount, resourcesLabel, rolesCount, rolesLabel, size, disabled, ...cardProps } = props;
	const { t } = useTranslation();
	const classes = useStyles();
	const isLarge = size === "large";

	const headerContent = useMemo(() => {
		if (integration) {
			return (
				<>
					<IntegrationImage
						className={classNames({ [classes.disabled]: disabled })}
						integration={integration}
						size={isLarge ? "medium" : "small"}
						noBorder
						noBackground
						noWrap
					/>
					<TooltipOnOverflow textVariant={isLarge ? "title_sb" : "body_sb"} content={integration.name} />
				</>
			);
		}

		return null;
	}, [classes.disabled, disabled, integration, isLarge]);

	const content = useMemo(
		() => (
			<ResourceAndRoleCountCardBody
				resourcesCount={resourcesCount}
				resourcesLabel={resourcesLabel}
				rolesCount={rolesCount}
				rolesLabel={rolesLabel}
				size={size}
			/>
		),
		[resourcesCount, resourcesLabel, rolesCount, rolesLabel, size]
	);

	const tooltipContent = useMemo(
		() =>
			disabled ? (
				<TitleBody
					size="medium"
					title={
						<Typography variant="text_sm_sb">
							{t("pages.newRequest.selectRolesStep.missingActors.integration.title")}
						</Typography>
					}
					body={
						<Typography variant="text_sm_reg">
							{t("pages.newRequest.selectRolesStep.missingActors.integration.description")}
						</Typography>
					}
				/>
			) : undefined,
		[disabled, t]
	);

	return (
		<Card
			size={size}
			disabled={disabled}
			disabledTooltip={tooltipContent}
			content={content}
			header={headerContent}
			{...cardProps}
		/>
	);
};
