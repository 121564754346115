import constate from "constate";
import { Map, Record } from "immutable";
import { useCallback, useMemo, useRef } from "react";
import { getUser, multiGetUsers } from "api/users";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useLoadUsersInsights } from "hooks/useLoadUsersInsights";
import { useThrottledBulkFetch } from "hooks/useThrottledBulkFetch";
import { UserModel } from "models/UserModel";
import { notEmpty } from "utils/comparison";

type TUserState = { loading: boolean; data: UserModel | null; hadError: boolean };
class UserLoadingState extends Record<TUserState>({ loading: false, data: null, hadError: false }) {}

const useFullUsersState = () => {
	const fullUsersStateRef = useRef(Map<string, UserLoadingState>());

	const setFullUsersState = useCallback((newState: Map<string, UserLoadingState>) => {
		fullUsersStateRef.current = newState;
	}, []);

	const getFullUsersState = useCallback(() => fullUsersStateRef.current, []);

	return { setFullUsersState, getFullUsersState };
};

const useUsers = () => {
	const {
		itemsById: users,
		loadIds: loadUsers,
		setItemsById: setUsers
	} = useThrottledBulkFetch(multiGetUsers, {
		throttleTime: 50,
		includeDeleted: true
	});
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const { setFullUsersState, getFullUsersState } = useFullUsersState();

	const upsertUser = useCallback(
		(user: UserModel) => {
			const currentFullUsersState = getFullUsersState();
			const currentUserFullState = currentFullUsersState.get(user.id);
			const newUserFullState = (currentUserFullState || new UserLoadingState())
				.set("loading", false)
				.set("data", currentUserFullState?.data ? currentUserFullState.data.merge(user) : user);
			setFullUsersState(currentFullUsersState.set(user.id, newUserFullState));
			setUsers(current => {
				const currentUser = current.get(user.id);
				if (currentUser) {
					return current.set(user.id, currentUser.merge(user));
				}
				return current?.set(user.id, user) || current;
			});
		},
		[getFullUsersState, setFullUsersState, setUsers]
	);

	const loadFullUser: (id: string) => Promise<UserModel | null> = useCallback(
		async (id: string) => {
			let currentFullUsersState = getFullUsersState();
			try {
				const state = currentFullUsersState.get(id) || new UserLoadingState();
				if (!state.loading) {
					setFullUsersState(currentFullUsersState.set(id, state.set("loading", true)));
					const fullUser = await getUser(id);
					upsertUser(fullUser);
					return fullUser;
				}
				return state.data;
			} catch (error) {
				openGlobalErrorModal(error as Error);
				currentFullUsersState = getFullUsersState();
				setFullUsersState(
					currentFullUsersState.set(id, currentFullUsersState.get(id)!.set("loading", false).set("hadError", true))
				);
				return null;
			}
		},
		[getFullUsersState, openGlobalErrorModal, setFullUsersState, upsertUser]
	);

	const loadUser = useCallback((id: string) => loadUsers([id]), [loadUsers]);

	const updateUsers = useCallback(
		(users: Partial<UserModel>[]) => {
			setUsers(current => {
				users.forEach(partialUser => {
					if (!partialUser.id) return;

					const user = current.get(partialUser.id);
					if (!user) return;

					current = current.set(partialUser.id, user.merge(partialUser));
				});
				return current;
			});
		},
		[setUsers]
	);

	const fullUsersState = getFullUsersState();

	const fullUsers = useMemo(() => fullUsersState.map(value => value.data).filter(notEmpty), [fullUsersState]);

	const sortedUsers = useMemo(
		() =>
			users
				.filter(notEmpty)
				?.toList()
				?.sortBy(user => user.fullName),
		[users]
	);

	const addUsersToContext = useCallback(
		(newUsers: UserModel[]) => {
			const missingUsers = newUsers.filter(user => !users.has(user.id));
			if (!missingUsers.length) return;
			setUsers(curr => {
				missingUsers.forEach(user => {
					curr = curr.set(user.id, user);
				});
				return curr;
			});
		},
		[setUsers, users]
	);

	const { isLoading: isLoadingInsights } = useLoadUsersInsights(users, updateUsers);

	return {
		state: {
			users,
			sortedUsers,
			fullUsers,
			fullUsersState,
			isLoadingInsights
		},
		actions: { loadUsers, loadUser, loadFullUser, upsertUser, addUsers: addUsersToContext, updateUsers }
	};
};

export const [UsersProvider, useUsersContext] = constate(useUsers);
