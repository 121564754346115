import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	selectOption: {
		display: "flex",
		alignItems: "center",
		gap: "var(--spacing-x3, 12px)",
		padding: "var(--spacing-x3, 12px) var(--spacing-x4, 16px)",
		color: "var(--color-black, #272931)"
	}
});
