import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	detectionTooltip: {
		display: "flex",
		flexDirection: "column",
		padding: "var(--spacing-x2, 8px)",
		gap: "var(--spacing-x5, 20px)",
		maxWidth: "436px",
		overflow: "scroll"
	},
	detectionSummary: {
		display: "flex"
	},
	summary: {
		marginLeft: "var(--spacing-x1, 4px)"
	},
	riskRatio: {
		marginLeft: "var(--spacing-x1, 4px)",
		display: "flex",
		minWidth: "fit-content"
	}
});
