import classNames from "classnames";
import React, { MouseEventHandler, ReactNode, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { TTypographyVariant, Typography } from "components/ui/Typography";
import { useSystemOverlays } from "context/overlaysContext";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { useStopPropagation } from "hooks/useStopPropagation";
import { useTooltip } from "hooks/useTooltip";
import { useStyles } from "./styles";
import { ChevronDownIcon } from "../Icons/ChevronDownIcon";
import { ExportIcon } from "../Icons/ExportIcon";
import type { Placement } from "@popperjs/core";

type TDropdownButtonVariant = "primary" | "secondary";
type TDropdownButtonSize = "small" | "medium" | "large";

type TBaseDropDownButtonProps = {
	disabled?: boolean;
	withoutButtonWrapper?: boolean;
	loading?: boolean;
	onClick?: MouseEventHandler;
	onClose: () => void;
	open: boolean;
	position?: Placement;
	prefix?: ReactNode;
	size?: TDropdownButtonSize;
	suffix?: ReactNode;
	variant?: TDropdownButtonVariant;
};

type TCustomDropdownButtonProps = TBaseDropDownButtonProps & {
	dropdown: ReactNode;
	options?: never;
	onSelectOption?: never;
};

export type TBasicDropdownButtonProps = TBaseDropDownButtonProps & {
	dropdown?: never;
	options: {
		label: React.ReactNode;
		value: string;
		icon?: IconComponent;
		disabled?: boolean;
		linkProps?: { to: string; target?: "_blank" | "_self" | "_parent" | "_top" };
	}[];
	onSelectOption: (option: string) => unknown;
};

type TDropdownButtonProps = TCustomDropdownButtonProps | TBasicDropdownButtonProps;

const useButtonClasses = (props: {
	active?: boolean;
	withoutButtonWrapper?: boolean;
	className?: string;
	disabled?: boolean;
	loading?: boolean;
	size: TDropdownButtonSize;
	variant: TDropdownButtonVariant;
}) => {
	const { active, size, variant, loading, disabled, withoutButtonWrapper, className } = props;
	const classes = useStyles();
	const sizeClassName = useMemo(() => {
		switch (size) {
			case "large":
				return classes.large;
			case "medium":
				return classes.medium;
			case "small":
				return classes.small;
		}
	}, [classes.large, classes.medium, classes.small, size]);

	const variantClassName = useMemo(() => {
		switch (variant) {
			case "primary":
				return classes.primary;
			case "secondary":
				return classes.secondary;
		}
	}, [classes.primary, classes.secondary, variant]);

	const buttonClassName = useMemo(() => {
		return classNames(
			classes.button,
			{
				[classes.disabled]: disabled,
				[sizeClassName]: !withoutButtonWrapper,
				[variantClassName]: !withoutButtonWrapper,
				[classes.loading]: loading,
				[classes.active]: active || loading
			},
			className
		);
	}, [
		active,
		className,
		classes.active,
		classes.button,
		classes.disabled,
		classes.loading,
		withoutButtonWrapper,
		disabled,
		loading,
		sizeClassName,
		variantClassName
	]);

	return { buttonClassName, sizeClassName, variantClassName };
};

export const DropdownButton: FC<TDropdownButtonProps> = props => {
	const {
		children,
		className,
		disabled = false,
		dropdown: propDropdown,
		loading = false,
		onClick: propOnClick,
		onClose,
		onSelectOption,
		open,
		options,
		position = "auto",
		prefix,
		size = "large",
		suffix = <ChevronDownIcon size={size} />,
		variant = "primary",
		withoutButtonWrapper = false
	} = props;
	const classes = useStyles();
	const systemOverlays = useSystemOverlays();

	const { buttonClassName } = useButtonClasses({
		className,
		disabled,
		size,
		variant,
		active: open,
		loading,
		withoutButtonWrapper
	});
	const wrapperRef = useRef<HTMLDivElement | null>(null);

	const textVariant: TTypographyVariant = useMemo(() => {
		switch (size) {
			case "large":
				return "title_med";
			case "medium":
				return "text_title_sb";
			case "small":
				return "text_sm_sb";
		}
	}, [size]);

	const content = useMemo(() => {
		if (withoutButtonWrapper) return children;
		return (
			<>
				{prefix ? <div className={classes.prefixOrSuffix}>{prefix}</div> : null}
				<Typography noWrap className={classes.innerText} variant={textVariant}>
					{children}
				</Typography>
				{loading || suffix ? (
					<div className={classes.prefixOrSuffix}>{loading ? <LoadingSpinner inline /> : suffix || null}</div>
				) : null}
			</>
		);
	}, [children, classes.innerText, classes.prefixOrSuffix, withoutButtonWrapper, loading, prefix, suffix, textVariant]);

	const tooltipOptions = useMemo(() => ({ visible: open, placement: position }), [position, open]);
	const { visible, setTooltipRef, tooltipProps, setTriggerRef, tooltipRef } = useTooltip(tooltipOptions);

	useOnClickOutside(tooltipRef || undefined, onClose, wrapperRef);

	const dropdown = useMemo(() => {
		if (propDropdown) return propDropdown;
		if (!options || !onSelectOption) return null;

		return (
			<div className={classes.dropdown}>
				{options.map(option => {
					const Icon = option.icon;
					const onClick = (ev: React.MouseEvent) => {
						ev.stopPropagation();
						if (option.disabled) return;
						onSelectOption(option.value);
						onClose();
					};
					return !option.linkProps ? (
						<div
							key={option.value}
							className={classNames(classes.option, { [classes.disabled]: option.disabled })}
							onClick={onClick}>
							{Icon ? <Icon disabled={option.disabled} className={classes.optionIcon} /> : null}
							<Typography variant="text_reg">{option.label}</Typography>
						</div>
					) : (
						<a
							key={option.value}
							className={classNames(classes.option, { [classes.disabled]: option.disabled })}
							href={option.linkProps.to}
							target={option.linkProps.target}>
							{Icon ? <Icon disabled={option.disabled} className={classes.optionIcon} /> : null}
							<Typography variant="text_reg">{option.label}</Typography>
							<ExportIcon className={classes.optionIcon} />
						</a>
					);
				})}
			</div>
		);
	}, [
		classes.optionIcon,
		propDropdown,
		options,
		onSelectOption,
		classes.dropdown,
		classes.option,
		classes.disabled,
		onClose
	]);

	const dropdownPortal = useMemo(() => {
		return createPortal(
			<div className={classes.wrapper} ref={wrapperRef}>
				<div ref={setTooltipRef} {...tooltipProps}>
					{dropdown}
				</div>
			</div>,
			systemOverlays ?? document.body
		);
	}, [classes.wrapper, dropdown, setTooltipRef, tooltipProps, systemOverlays]);

	const onClick = useStopPropagation(propOnClick);

	return (
		<React.Fragment>
			<button
				className={buttonClassName}
				ref={setTriggerRef}
				onClick={!disabled && !loading ? onClick : undefined}
				type="button">
				{content}
			</button>
			{visible ? dropdownPortal : null}
		</React.Fragment>
	);
};
