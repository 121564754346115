import { List } from "immutable";
import React, { useMemo } from "react";
import { InsightsIndicatorTitle } from "components/common/insights/InsightsIndicatorTitle";
import { SensitivityIndicator } from "components/common/insights/SensitivityIndicator";
import { TitleBody } from "components/ui/TitleBody";
import { PrivilegeLevels, SeverityLevels } from "context/insightsContext";
import { getMaxEnumValue } from "utils/enums";
import { useStyles } from "./styles";
import { RiskIndicator } from "../../RiskIndicator";
import { DetectionSummary } from "../DetectionSummary/DetectionSummary";
import type { TInsightsIndicatorIconColor } from "components/common/insights/InsightsIndicator";
import type { InsightsDetectionModel } from "models/InsightsDetectionModel";

export const DetectionsTooltip: FC<{
	detections: List<InsightsDetectionModel>;
	isRisk?: boolean;
	titleLevel: number;
	titleColor: TInsightsIndicatorIconColor;
	titleText: string;
	getLevel: (indicator: number) => PrivilegeLevels | SeverityLevels;
}> = ({ detections, titleLevel, titleColor, titleText, getLevel, isRisk = false }) => {
	const classes = useStyles();
	const totalLevels = getMaxEnumValue(isRisk ? SeverityLevels : PrivilegeLevels);

	const title = useMemo(() => {
		const IndicatorComponent = isRisk ? RiskIndicator : SensitivityIndicator;

		return (
			<InsightsIndicatorTitle
				IndicatorComponent={IndicatorComponent}
				level={titleLevel}
				color={titleColor}
				text={titleText}
				totalLevels={totalLevels}
				amount={detections.size}
			/>
		);
	}, [detections.size, isRisk, titleColor, titleLevel, titleText, totalLevels]);

	const body = useMemo(
		() => (
			<div className={classes.detectionsWrapper}>
				{detections.map((detection, i) => {
					const indicator = isRisk ? detection.severity : detection.sensitivity || 0;
					const level = getLevel(indicator);

					return (
						<DetectionSummary key={`detection_${i}`} detection={detection} level={level} totalLevels={totalLevels} />
					);
				})}
			</div>
		),
		[classes.detectionsWrapper, detections, getLevel, isRisk, totalLevels]
	);

	return <TitleBody size="small" className={classes.tooltipContent} title={title} body={body} bodyTooltipOnOverflow />;
};
