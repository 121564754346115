import classNames from "classnames";
import React, { useMemo } from "react";
import { Tooltip } from "components/ui/Tooltip";
import { Typography } from "components/ui/Typography";
import { useStyles } from "./styles";
import type { PrivilegeLevels, SeverityLevels } from "context/insightsContext";

export type TInsightsIndicatorIconColor = "red" | "orange" | "grey";

export type TInsightsIndicator = {
	level?: PrivilegeLevels | SeverityLevels;
	size?: number;
	tooltipContent?: React.ReactNode | FC;
	color?: TInsightsIndicatorIconColor;
	Icon: IconComponent;
	levelClassName?: string;
};

const DELAY_SHOW_TOOLTIP = 500;

export const InsightsIndicator: FC<TInsightsIndicator> = ({
	size = 20,
	tooltipContent,
	level,
	className,
	Icon,
	color = "grey",
	levelClassName = false
}) => {
	const classes = useStyles();

	const iconColorClass = useMemo(() => {
		switch (color) {
			case "red":
				return classes.red;
			case "orange":
				return classes.orange;
			case "grey":
				return classes.grey;
		}
	}, [classes.grey, classes.orange, classes.red, color]);

	return (
		<Tooltip className={classes.tooltipClassName} content={tooltipContent} delayShow={DELAY_SHOW_TOOLTIP}>
			<div className={classNames(className, classes.riskIndicator)}>
				<Icon size={size} className={iconColorClass} />
				{level ? (
					<Typography variant="text_tny_sb" className={classNames(classes.level, levelClassName)}>
						{level}
					</Typography>
				) : null}
			</div>
		</Tooltip>
	);
};
