import { List, Record } from "immutable";
import { TicketApprovalEntityApproverModel } from "./TicketApprovalEntityApproverModel";
import { TicketApprovalRequestModel } from "./TicketApprovalRequestModel";

type TTicketApprovalEntityType =
	| "Automatic"
	| "DirectManager"
	| "DirectoryGroup"
	| "HR"
	| "IntegrationMaintainer"
	| "IntegrationOwner"
	| "OnCallIntegrationSchedule"
	| "ResourceMaintainer"
	| "ResourceOwner"
	| "TeamMember"
	| "User"
	| "Webhook";

const defaults = {
	approvers: List<TicketApprovalEntityApproverModel>(),
	displayName: "",
	id: "",
	identifier: null as string | null,
	request: null as TicketApprovalRequestModel | null,
	ticketApprovalRequestId: "",
	type: "" as TTicketApprovalEntityType
};

export class TicketApprovalEntityModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): TicketApprovalEntityModel {
		const { approvers, id, displayName, identifier, request, ticketApprovalRequestId, type } = data as typeof defaults;

		return new TicketApprovalEntityModel({
			approvers: approvers
				? List(approvers.map(approver => TicketApprovalEntityApproverModel.fromServerData(approver)))
				: List(),
			displayName,
			id,
			identifier,
			request: request ? TicketApprovalRequestModel.fromServerData(request) : null,
			ticketApprovalRequestId,
			type
		});
	}

	get approverIds(): List<string> {
		return this.approvers.flatMap(approver => approver.userIds);
	}
}
