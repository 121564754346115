import { List, Map } from "immutable";
import { uniqBy } from "lodash";
import { useEffect, useMemo } from "react";
import { getInsightsIdentities } from "api/insights";
import { useInsightsContext } from "context/insightsContext";
import { InsightsIdentityModel } from "models/InsightsIdentityModel";
import { notEmpty } from "utils/comparison";
import { useFetchedState } from "./useFetchedState";
import { useUpdateInsightsUsers } from "./useUpdateInsightsUsers";
import type { UserModel } from "models/UserModel";

const useFetchInsightIdentities = (
	idByEmail: Map<string, string>,
	users: Map<string, UserModel | null>,
	updateUsers: (users: Partial<UserModel>[]) => void
) => {
	const { data: identities, forceLoadData, isLoading } = useFetchedState(getInsightsIdentities);

	// Insights email might have multiple identities and our system doesn't support this approach - that's why we need to merge them
	const mergedIdentities = useMemo(() => {
		if (!identities) return null;

		return identities
			.groupBy(identity => identity.email)
			.map(emailIdentities => {
				const mergedIdentity = emailIdentities.reduce((acc, identity) => {
					const { accounts, email, id } = identity;
					const sensitivity = Math.max(acc.sensitivity, identity.sensitivity);
					const mergedAccounts = acc.accounts && accounts ? acc.accounts.concat(accounts) : accounts;

					return acc.set("id", id).set("email", email).set("sensitivity", sensitivity).set("accounts", mergedAccounts);
				}, new InsightsIdentityModel());

				const identityAccounts = uniqBy(mergedIdentity.accounts?.toArray(), "id");
				return mergedIdentity.set("accounts", List(identityAccounts));
			})
			.valueSeq()
			.toList();
	}, [identities]);

	const { insightsUsersExistsInContext, insightsUsersNotInContext } = useUpdateInsightsUsers({
		idByEmail,
		users,
		identities: mergedIdentities,
		updateUsers
	});

	return { insightsUsersExistsInContext, insightsUsersNotInContext, forceLoadData, isLoading };
};

export const useLoadUsersInsights = (
	users: Map<string, UserModel | null>,
	updateUsers: (users: Partial<UserModel>[]) => void
) => {
	const {
		state: { isInsightsEnabled, partialUsers, usersToFetch, emailsRequestedRef },
		actions: { removePartialUsers, removeUsersToFetch }
	} = useInsightsContext();

	const missingInsightsUsers = useMemo(
		() =>
			users
				.filter(user => notEmpty(user) && !user.insightsData?.fullDetectionsData)
				.map(user => ({ id: (user as UserModel).id, email: (user as UserModel).email }))
				.merge(usersToFetch)
				.toList()
				.toArray(),
		[users, usersToFetch]
	);

	const missingInsightsDataUserEmails = useMemo(
		() => missingInsightsUsers.filter(user => !partialUsers.has(user.id)).map(user => user.email),
		[missingInsightsUsers, partialUsers]
	);

	const idByEmail = useMemo(
		() => Map(missingInsightsUsers.map(user => [user.email.toLowerCase(), user.id])),
		[missingInsightsUsers]
	);

	const { insightsUsersNotInContext, forceLoadData, isLoading } = useFetchInsightIdentities(
		idByEmail,
		users,
		updateUsers
	);

	// Effect to fetch insights data
	useEffect(() => {
		if (!isInsightsEnabled) return;

		if (usersToFetch.size && insightsUsersNotInContext.length) {
			const userIds = emailsRequestedRef.current
				.toArray()
				.map(email => idByEmail.get(email.toLowerCase()))
				.filter(notEmpty);

			removeUsersToFetch(userIds);
		}

		const emailsToFetch = missingInsightsDataUserEmails.filter(email => !emailsRequestedRef.current.has(email));
		if (emailsToFetch.length && !isLoading) {
			emailsRequestedRef.current = emailsRequestedRef.current.merge(emailsToFetch);
			void forceLoadData(emailsToFetch);
		}
	}, [
		isInsightsEnabled,
		emailsRequestedRef,
		forceLoadData,
		missingInsightsDataUserEmails,
		usersToFetch.size,
		removeUsersToFetch,
		idByEmail,
		insightsUsersNotInContext.length,
		isLoading
	]);

	// Effect to update users with existing partial insights data - and remove them from the partial state
	useEffect(() => {
		const usersWithInsightsDataNotInContext = missingInsightsUsers.filter(
			user => !usersToFetch.has(user.id) && partialUsers.has(user.id)
		);
		if (!usersWithInsightsDataNotInContext.length) return;

		updateUsers(usersWithInsightsDataNotInContext);
		removePartialUsers(usersWithInsightsDataNotInContext.map(user => user.id));
	}, [missingInsightsUsers, partialUsers, removePartialUsers, updateUsers, usersToFetch]);

	return {
		isLoading
	};
};
