import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { UserNode } from "components/common/entities/Nodes/UserNode";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { UserModel } from "models/UserModel";
import { useStyles } from "./styles";

type TProps = {
	onBehalf?: boolean;
	requestedBy: string | UserModel;
};

export const RequestedBy: FC<TProps> = ({ className, innerRef, onBehalf, requestedBy }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div className={classNames(className, classes.container)} ref={innerRef}>
			<TooltipOnOverflow
				textVariant="text_sm_sb"
				content={t(`common.requestBar.requestedBy.${onBehalf ? "behalfOfTitle" : "title"}`)}
			/>
			<UserNode user={requestedBy} />
		</div>
	);
};
