import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	tooltip: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2, 8px)"
	},
	link: {
		marginLeft: "var(--spacing-x1, 4px)"
	}
});
