import { useEffect } from "react";
import { compiledClientConfig } from "config";
import { useAuthContext } from "context/authContext";
import { useCompanyContext } from "context/companyContext";
import { CompanyModel } from "models/CompanyModel";

export const useCompany = (): CompanyModel | null => {
	const { isLoggedIn } = useAuthContext();
	const {
		state: { company },
		actions: { loadCompany }
	} = useCompanyContext();

	useEffect(() => {
		if (!isLoggedIn && !compiledClientConfig.spog) return;

		void loadCompany();
	}, [isLoggedIn, loadCompany]);

	return company;
};
