import constate from "constate";
import { List, Map, Set } from "immutable";
import { useCallback, useRef, useState } from "react";
import { compiledClientConfig } from "config";
import { useCompany } from "hooks/useCompany";
import type { UserModel } from "models/UserModel";

type TUserAndEmail = { id: string; email: string };
export type TRisk = "risky" | "caution" | "all";
export type TSensitivity = "high" | "medium" | "all";

/*
 * IMPORTANT: DO NOT CHANGE
 *
 * These enums and functions are used in the original codebase of the insights API.
 * They are sourced from the following URL:
 * https://github.com/BeyondTrust/platform-identity/blob/897c6982ba82093dec68813e64de982ef2094313/projects/platform-identity/src/app/utils/typeMapping.ts#L19
 *
 * Any changes to these enums should be made in the original codebase and not here.
 */
export enum SeverityLevels {
	CRITICAL = 4,
	HIGH = 3,
	MODERATE = 2,
	LOW = 1,
	NONE = 0
}

export enum PrivilegeLevels {
	HIGHEST = 4,
	HIGH = 3,
	MODERATE = 2,
	LOW = 1,
	NONE = 0
}

export function mapSeverityToSeverityLevel(severity?: number): SeverityLevels {
	if (!severity) {
		return SeverityLevels.NONE;
	}

	if (severity >= 10) {
		return SeverityLevels.CRITICAL;
	} else if (severity >= 7 && severity < 10) {
		return SeverityLevels.HIGH;
	} else if (severity >= 4) {
		return SeverityLevels.MODERATE;
	} else if (severity >= 0) {
		return SeverityLevels.LOW;
	}
	return SeverityLevels.NONE;
}

export function mapFromPrivilegeLevels(privilege?: number | null): PrivilegeLevels {
	if (!privilege) {
		return PrivilegeLevels.NONE;
	}

	if (privilege >= 10) {
		return PrivilegeLevels.HIGHEST;
	} else if (privilege >= 7 && privilege < 10) {
		return PrivilegeLevels.HIGH;
	} else if (privilege >= 3 && privilege < 7) {
		return PrivilegeLevels.MODERATE;
	} else if (privilege > 0 && privilege < 3) {
		return PrivilegeLevels.LOW;
	}
	return PrivilegeLevels.NONE;
}

export function mapSeverityLevelToSeverity(severityLevel: SeverityLevels): number {
	switch (severityLevel) {
		case SeverityLevels.CRITICAL:
			return 10;
		case SeverityLevels.HIGH:
			return 7;
		case SeverityLevels.MODERATE:
			return 4;
		case SeverityLevels.LOW:
			return 1;
		case SeverityLevels.NONE:
			return 0;
	}
}

export const mapPrivilegeLevelToPrivilege = (privilegeLevel: PrivilegeLevels): number => {
	switch (privilegeLevel) {
		case PrivilegeLevels.HIGHEST:
			return 10;
		case PrivilegeLevels.HIGH:
			return 7;
		case PrivilegeLevels.MODERATE:
			return 3;
		case PrivilegeLevels.LOW:
			return 1;
		case PrivilegeLevels.NONE:
			return 0;
	}
};

/*
 * END OF ORIGINAL CODEBASE
 */

export const getRiskLabel = (severity?: number): TRisk | null => {
	const severityLevel = mapSeverityToSeverityLevel(severity);

	switch (severityLevel) {
		case SeverityLevels.CRITICAL:
		case SeverityLevels.HIGH:
			return "risky";
		case SeverityLevels.MODERATE:
		case SeverityLevels.LOW:
			return "caution";
		case SeverityLevels.NONE:
			return null;
	}
};

export const getSensitivityLabel = (sensitivity?: number): TSensitivity | null => {
	const sensitivityLevel = mapFromPrivilegeLevels(sensitivity);

	switch (sensitivityLevel) {
		case PrivilegeLevels.HIGHEST:
		case PrivilegeLevels.HIGH:
			return "high";
		case PrivilegeLevels.MODERATE:
		case PrivilegeLevels.LOW:
			return "medium";
		case PrivilegeLevels.NONE:
			return null;
	}
};

const useInsights = () => {
	// In order to block multiple requests for the same emails
	const emailsRequestedRef = useRef(Set<string>());
	const company = useCompany();
	const isInsightsEnabled =
		(company?.enableInsights && compiledClientConfig.spog) || compiledClientConfig.insights.enable || false;

	// Users with insights data that are still not in the users context
	// We don't add it to the users context yet because it's partial users - id and insights data only
	// When the users are loaded to the users context, we add this data to the user and remove it from this state
	const [partialUsersWithInsightsData, setPartialUsersWithInsightsData] = useState(Map<string, Partial<UserModel>>);
	// Users that are not in the users context and we need to fetch insights data for them
	const [usersToFetchNotInContext, setUsersToFetchNotInContext] = useState(Map<string, TUserAndEmail>());
	const [tenantGraphUsers, setTenantGraphUsers] = useState(List<TUserAndEmail>());

	const addPartialUsers = useCallback((users: Partial<UserModel>[]) => {
		setPartialUsersWithInsightsData(current => {
			users.forEach(user => {
				if (!user.id) return;
				current = current.set(user.id, user);
			});
			return current;
		});
	}, []);

	const removePartialUsers = useCallback((userIds: string[]) => {
		setPartialUsersWithInsightsData(current => {
			userIds.forEach(userId => {
				current = current.delete(userId);
			});
			return current;
		});
	}, []);

	const addUsersToFetchNotInContext = useCallback((usersNotInContext: TUserAndEmail[]) => {
		const newUsersToFetch = Map(
			usersNotInContext.filter(user => !emailsRequestedRef.current.has(user.email)).map(user => [user.id, user])
		);
		setUsersToFetchNotInContext(current => current.merge(newUsersToFetch));
	}, []);

	const removeUsersToFetchNotInContext = useCallback((userIds: string[]) => {
		setUsersToFetchNotInContext(current => {
			userIds.forEach(userId => {
				current = current.delete(userId);
			});
			return current;
		});
	}, []);

	return {
		state: {
			isInsightsEnabled,
			partialUsers: partialUsersWithInsightsData,
			usersToFetch: usersToFetchNotInContext,
			emailsRequestedRef,
			tenantGraphUsers
		},
		actions: {
			addPartialUsers,
			removePartialUsers,
			addUsersToFetch: addUsersToFetchNotInContext,
			removeUsersToFetch: removeUsersToFetchNotInContext,
			updateTenantGraphUsers: setTenantGraphUsers
		}
	};
};

export const [InsightsProvider, useInsightsContext] = constate(useInsights);
