import classNames from "classnames";
import React, { useMemo } from "react";
import { AccountIcon } from "components/ui/Icons/AccountIcon";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { GroupIcon } from "components/ui/Icons/GroupIcon";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { TagIcon } from "components/ui/Icons/TagIcon";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { useStyles } from "./styles";
import { Chip, TChipVariant, TChipSize } from "../Chip";

export type THiddenChipType =
	| "tag"
	| "user"
	| "account"
	| "bundle"
	| "integration"
	| "resource"
	| "group"
	| "role"
	| "owner"
	| "maintainer"
	| "requestable"
	| "requests"
	| "workflow";

interface IHiddenChipProps {
	SuffixIcon?: IconComponent;
	readonly?: boolean;
	hasSuffix?: boolean;
	type?: THiddenChipType;
	size?: TChipSize;
	variant?: TChipVariant;
	selected?: boolean;
	onDelete?: () => void;
}

export const HiddenChip: FC<IHiddenChipProps> = ({
	children,
	className,
	hasSuffix = false,
	innerRef,
	readonly = false,
	selected = false,
	size = "medium",
	type,
	variant = "regular"
}) => {
	const getIconFromType = useMemo(() => {
		switch (type) {
			case "tag":
				return TagIcon;
			case "user":
				return UserCircleIcon;
			case "account":
				return AccountIcon;
			case "bundle":
				return BundleIcon;
			case "integration":
				return IntegrationIcon;
			case "resource":
				return ResourcesIcon;
			case "group":
				return GroupIcon;
			case "role":
				return RoleIcon;
			case "requests":
				return RequestsIcon;
			default:
				return undefined;
		}
	}, [type]);

	const classes = useStyles();

	return (
		<Chip
			PrefixIcon={getIconFromType}
			SuffixIcon={hasSuffix ? ChevronDownIcon : undefined}
			className={classNames(classes.hiddenChip, className)}
			innerRef={innerRef}
			noTooltip
			readonly={readonly}
			selected={selected}
			size={size}
			variant={variant}>
			{children}
		</Chip>
	);
};
