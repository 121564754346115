import { Record } from "immutable";

export interface IInsightsDetectionResponseNode {
	privilegeValue: number | null;
	entity: {
		primaryEmail: string;
	};
	insight: {
		id: string;
		definition: {
			id: string;
			severity: number;
			summary: string;
			metadata: {
				remediation: string;
				detail: string;
				concern: string;
			};
		};
	};
}

const defaults = {
	id: "",
	insightId: "",
	severity: 0,
	sensitivity: null as 0 | null,
	summary: "",
	email: "",
	metadata: {
		detail: "",
		concern: "",
		remediation: ""
	}
};

export class InsightsDetectionModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: IInsightsDetectionResponseNode): InsightsDetectionModel {
		const { privilegeValue } = data;
		const { definition } = data.insight;
		const { primaryEmail } = data.entity;

		const { id, insightId, email, severity, sensitivity, summary, metadata } = {
			id: definition.id,
			insightId: data.insight.id,
			email: primaryEmail,
			severity: definition.severity,
			sensitivity: privilegeValue,
			summary: definition.summary,
			metadata: definition.metadata
		} as typeof defaults;

		return new InsightsDetectionModel({
			id,
			insightId,
			email,
			severity,
			sensitivity,
			summary,
			metadata
		});
	}
}
