import { List } from "immutable";
import { type IInsightsDetectionResponseNode, InsightsDetectionModel } from "models/InsightsDetectionModel";
import { type IInsightsIdentityResponseNode, InsightsIdentityModel } from "models/InsightsIdentityModel";
import {
	type IInsightsDetectionsGroupingResponseNode,
	TenantInsightsGroupingModel
} from "models/InsightsTenantGroupingModel";
import { graphqlGetAllInsightsPages, graphqlInsightsReq } from "utils/api/insightsGraphqlReq";
import {
	GET_IDENTITIES_DETECTIONS_QUERY,
	GET_IDENTITIES_QUERY,
	GET_INSIGHTS_TENANT_QUERY
} from "./queries/identityGraph.queries";

export const getUsersDetections = async (emails: string[]) => {
	const variables = { emails };

	const data = (await graphqlGetAllInsightsPages({
		query: GET_IDENTITIES_DETECTIONS_QUERY,
		variables
	})) as IInsightsDetectionResponseNode[];

	return List<InsightsDetectionModel>(data.map(node => InsightsDetectionModel.fromServerData(node)));
};

export const getInsightsIdentities = async (emails: string[]) => {
	const variables = { emails };

	const data = (await graphqlGetAllInsightsPages({
		query: GET_IDENTITIES_QUERY,
		variables
	})) as IInsightsIdentityResponseNode[];

	return List<InsightsIdentityModel>(data.map(node => InsightsIdentityModel.fromServerData(node)));
};

export const getTenantInsights = async () => {
	const variables = {
		statsTypes: [
			"IDENTITY_HIGHEST_PRIVILEGE_COUNT",
			"IDENTITY_HIGH_PRIVILEGE_COUNT",
			"IDENTITY_MODERATE_PRIVILEGE_COUNT",
			"IDENTITY_LOW_PRIVILEGE_COUNT",
			"IDENTITY_NONE_PRIVILEGE_COUNT",
			"IDENTITY_UNDETERMINED_PRIVILEGE_COUNT"
		],
		lowSevFilter: {
			lte: 3
		},
		modSevFilter: {
			gt: 3,
			lte: 6
		},
		highSevFilter: {
			gt: 6,
			lte: 9
		},
		critSevFilter: {
			gt: 9
		}
	};
	const { data } = await graphqlInsightsReq({
		query: GET_INSIGHTS_TENANT_QUERY,
		variables
	});

	return TenantInsightsGroupingModel.fromServerData(data as IInsightsDetectionsGroupingResponseNode);
};
